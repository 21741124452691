import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminSettings from "./AdminSetting";
import AlertMessage from "./AlertMessage";
import Auth0ProviderWithHistory from "./Auth0Provider";
import Challenges from "./Challenges";
import CreateChallenge from "./CreateChallenge";
import Footer from "./Footer";
import Home from "./Home";
import Leaderboard from "./Leaderboard";
import Profile from "./Profile";
import ProtectedRoute from "./ProtectedRoute";
import ReloadNotification from "./ReloadNotification";
import Settings from "./Settings";
import Sidebar from "./Sidebar";
import Users from "./Users";

function App() {
    const [showSettings, setShowSettings] = useState(false);

    return (
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <Sidebar showSettings={setShowSettings} />

                <ReloadNotification />
                <AlertMessage />
                <Switch>
                    <Route path="/Home">
                        <Home />
                    </Route>
                    <Route path="/Leaderboard">
                        <Leaderboard />
                    </Route>
                    <Route path="/Profile">
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/Challenges/Create">
                        <ProtectedRoute scopes={["read:challenges", "update:challenges"]}>
                            <CreateChallenge />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/Challenges">
                        <ProtectedRoute scopes={["read:challenges", "delete:challenges", "update:challenges"]}>
                            <Challenges />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/Users">
                        <ProtectedRoute scopes={["read:users", "update:users", "delete:users"]}>
                            <Users />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/AdminSettings">
                        <ProtectedRoute scopes={["update:webhooks"]}>
                            <AdminSettings />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>

                <Settings show={showSettings} setShow={setShowSettings} />

                <Footer />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    );
}

export default App;
