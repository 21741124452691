import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfHasScopes } from "./Constants";
import WebhookModel from "./models/WebhookModel";

function AdminSettings() {
    const [loading, setLoading] = useState(true);
    const [webhook, setWebhook] = useState(undefined as WebhookModel | undefined);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const history = useHistory();

    const fetchWebhook = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["update:webhooks"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/ManageWebhook", {
            method: "GET",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. AdminSettings (GET: ManageWebhook): ${response.statusText}(${response.status})`);
                }
                if (response.status === 204) {
                    return null;
                }
                return response.json();
            })
            .then((data) => {
                if (isMountedRef.current && data) {
                    setWebhook(data);
                    setLoading(false);
                } else {
                    setWebhook(undefined);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) {
                    setWebhook(undefined);
                    setLoading(false);
                    history.push("?errorMessage=Failed+to+retrieve+Webhook+information.");
                }
            });
    }, [getAccessTokenSilently, history, isAuthenticated, isLoading]);

    async function connectWebhook() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["update:webhooks"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/ManageWebhook", {
            method: "POST",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. AdminSettings (POST: ManageWebhook): ${response.statusText}(${response.status})`);
                }
                if (isMountedRef.current) {
                    fetchWebhook();
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) {
                    fetchWebhook();
                }
            });
    }

    async function disconnectWebhook() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["update:webhooks"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/ManageWebhook", {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. AdminSettings (DELETE: ManageWebhook): ${response.statusText}(${response.status})`);
                }
                if (isMountedRef.current) {
                    fetchWebhook();
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) {
                    fetchWebhook();
                }
            });
    }

    useEffect(() => {
        fetchWebhook();

        return () => {
            isMountedRef.current = false;
        };
    }, [fetchWebhook]);

    return (
        <div className="container">
            <h1>Admin Settings</h1>

            <h3>Webhook Connection to Strava</h3>
            {webhook && (
                <>
                    <div>Webhook ID: {webhook.id}</div>
                    <div>
                        <b>Do not spam this button!</b> <br />
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                disconnectWebhook();
                            }}
                        >
                            Disconnect
                        </button>
                    </div>
                </>
            )}
            {!webhook && !loading && (
                <div>
                    <b>Do not spam this button!</b> <br />
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            connectWebhook();
                        }}
                    >
                        Connect
                    </button>
                </div>
            )}
        </div>
    );
}

export default AdminSettings;
