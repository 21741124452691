import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import LoginButton, { LogoutButton } from "./AuthButtons";
import Logo from "./images/logo512.png";

interface Props {
    showSettings: Function;
}
function Sidebar({ showSettings }: Props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { isLoading, isAuthenticated, user } = useAuth0();

    return (
        <header>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="home" viewBox="0 0 16 16">
                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                </symbol>
                <symbol id="gear-fill" viewBox="0 0 16 16">
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </symbol>
                <symbol id="card-list" viewBox="0 0 16 16">
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                    <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </symbol>
                <symbol id="bar-chart" viewBox="0 0 16 16">
                    <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                </symbol>
                <symbol id="gear" viewBox="0 0 16 16">
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </symbol>
                <symbol id="people" viewBox="0 0 16 16">
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                </symbol>
            </svg>
            <Navbar variant="light" bg="light" collapseOnSelect expand={false}>
                <Container fluid={true}>
                    <Navbar.Toggle onClick={handleShow} />
                    <Link to="/" className="navbar-brand mx-auto">
                        <img src={Logo} alt="BHCCC Logo" className="mx-2 align-middle" width="30" height="30" />
                        BHCCC Challenges
                    </Link>
                    <Offcanvas show={show} onHide={handleClose} className="bg-dark text-white border-end-0">
                        <Container>
                            <Offcanvas.Header closeButton closeVariant="white">
                                <Offcanvas.Title>
                                    <img src={Logo} alt="BHCCC Logo" className="mx-2 align-middle" width="30" height="30" />
                                    BHCCC Challenges
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <hr className="m-0" />
                            <Offcanvas.Body>
                                <div className="d-flex flex-column">
                                    <ul className="nav nav-pills flex-column mb-auto">
                                        <li className="nav-item">
                                            <NavLink
                                                to="/Home"
                                                className="nav-link text-white"
                                                aria-current="page"
                                                onClick={handleClose}
                                                activeClassName="active"
                                                isActive={(match, location) => {
                                                    if (["/", "/Home"].includes(location.pathname)) {
                                                        return true;
                                                    }
                                                    return false;
                                                }}
                                            >
                                                <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                    <use xlinkHref="#home" />
                                                </svg>
                                                <span className="align-middle">Home</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/Leaderboard" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                    <use xlinkHref="#bar-chart" />
                                                </svg>
                                                <span className="align-middle">Leaderboard</span>
                                            </NavLink>
                                        </li>
                                        {!isLoading && isAuthenticated && (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/Challenges" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                        <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                            <use xlinkHref="#card-list" />
                                                        </svg>
                                                        <span className="align-middle">Challenges</span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/Users" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                        <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                            <use xlinkHref="#people" />
                                                        </svg>
                                                        <span className="align-middle">Users</span>
                                                    </NavLink>
                                                </li>
                                            </>
                                        )}
                                        <hr />
                                        <li className="nav-item">
                                            <div className="d-grid">
                                                <button
                                                    className="nav-link text-white text-start"
                                                    onClick={() => {
                                                        handleClose();
                                                        showSettings(true);
                                                    }}
                                                >
                                                    <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                        <use xlinkHref="#gear-fill" />
                                                    </svg>
                                                    <span className="align-middle">Settings</span>
                                                </button>
                                            </div>
                                        </li>
                                        {!isLoading && isAuthenticated && (
                                            <li className="nav-item">
                                                <NavLink to="/AdminSettings" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                    <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                        <use xlinkHref="#gear" />
                                                    </svg>
                                                    <span className="align-middle">Admin Settings</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        <hr />
                                        {!isLoading &&
                                            (isAuthenticated ? (
                                                <>
                                                    <li className="nav-item mb-2">
                                                        <NavLink to="/Profile" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                            <div className="d-inline me-1">
                                                                <img
                                                                    src={user?.picture}
                                                                    className="rounded-circle border"
                                                                    alt="Profile Pic"
                                                                    width="35"
                                                                    height="35"
                                                                />
                                                            </div>
                                                            <div className="d-inline ms-1 align-middle">{user?.name}</div>
                                                        </NavLink>
                                                    </li>
                                                    <div className="row">
                                                        <div className="d-grid col">
                                                            <LogoutButton className="btn btn-danger" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="nav-item">
                                                        <div className="row">
                                                            <div className="d-grid col">
                                                                <LoginButton className="btn btn-primary" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                    </ul>
                                </div>
                            </Offcanvas.Body>
                        </Container>
                    </Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
}

export default Sidebar;
