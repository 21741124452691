import { useCallback, useEffect, useState } from "react";
import ChallengeModel, { ChallengeType } from "./models/ChallengeModel";
import { ReactComponent as ThirtyXThirtyMedal } from "./images/certificate.svg";
import { ReactComponent as GoldMedal } from "./images/gold-medal.svg";
import { ReactComponent as SilverMedal } from "./images/silver-medal.svg";
import { ReactComponent as BronzeMedal } from "./images/bronze-medal.svg";
import LeaderboardModel from "./models/LeaderboardModel";
import {
    BootstrapTeamsTableColours,
    calculateTeamElevation,
    calculateTeamPoints,
    ChallengesJsonUri,
    convertMetresToFeet,
    getChallengeTypeTitle,
    getCurrentChallenge,
    getFriendlyDate,
    getSortOrderForChallengeType,
    getUserMedalComparisonValue,
    getUserTotalForChallengeType,
    sortChallengesByStartDate,
    sortLeaderboard,
    sortTeamsLeaderboard,
} from "./Constants";
import TeamsLeaderboardModel from "./models/TeamsLeaderboardModel";

function Leaderboard() {
    const [loading, setLoading] = useState(true);
    const [challenges, setChallenges] = useState([] as ChallengeModel[]);
    const [viewingChallenge, setViewingChallenge] = useState(undefined as ChallengeModel | undefined);
    const [leaderboard, setLeaderboard] = useState([] as LeaderboardModel[]);
    const [teamsLeaderboard, setTeamsLeaderboard] = useState([] as TeamsLeaderboardModel[]);
    const [expandedTeams, setExpandedTeams] = useState([] as string[]);

    const fetchChallenges = useCallback(async () => {
        return fetch(ChallengesJsonUri, { cache: "no-cache" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Leaderboard (GET: ChallengesJson): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: ChallengeModel[]) => {
                sortChallengesByStartDate(data);
                setChallenges(data);
            })
            .catch((error) => {
                console.error("Error: ", error);
                setLeaderboard([] as LeaderboardModel[]);
                setChallenges([] as ChallengeModel[]);
                setLoading(false);
            });
    }, []);

    const fetchLeaderboard = useCallback(
        async (uri: string) => {
            if (uri) {
                return fetch(uri, { cache: "no-cache" })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`Network response was not ok. Leaderboard (GET: LeaderboardJson): ${response.statusText}(${response.status})`);
                        }
                        return response.json();
                    })
                    .then((data: LeaderboardModel[] | TeamsLeaderboardModel[]) => {
                        // TODO: Find better way to do this!
                        var dataStr = JSON.stringify(data);
                        if (dataStr.includes("team_name")) {
                            let teamsLeaderboardModel: TeamsLeaderboardModel[] = JSON.parse(dataStr);
                            if (viewingChallenge?.type === ChallengeType.TeamsDraft1 || viewingChallenge?.type === ChallengeType.TeamsDraft2) {
                                var sortedTeamsLeaderboard = sortTeamsLeaderboard(teamsLeaderboardModel, viewingChallenge.type);
                                if (sortedTeamsLeaderboard[sortedTeamsLeaderboard.length - 1].team_name === "") {
                                    var newExpandedTeams = [];
                                    newExpandedTeams.push(`team_${sortedTeamsLeaderboard.length - 1}`);
                                    setExpandedTeams(newExpandedTeams);
                                } else {
                                    setExpandedTeams([]);
                                }
                                setTeamsLeaderboard(sortedTeamsLeaderboard);
                            } else {
                                setTeamsLeaderboard([] as TeamsLeaderboardModel[]);
                            }
                            setLeaderboard([] as LeaderboardModel[]);
                        } else {
                            let leaderboardModel: LeaderboardModel[] = JSON.parse(dataStr);
                            if (viewingChallenge?.type !== ChallengeType.TeamsDraft1 && viewingChallenge?.type !== ChallengeType.TeamsDraft2) {
                                var sortedLeaderboard = sortLeaderboard(leaderboardModel, getSortOrderForChallengeType(viewingChallenge?.type));
                                setLeaderboard(sortedLeaderboard);
                            } else {
                                setLeaderboard([] as LeaderboardModel[]);
                            }
                            setTeamsLeaderboard([] as TeamsLeaderboardModel[]);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error("Error: ", error);
                        setLeaderboard([] as LeaderboardModel[]);
                        setLoading(false);
                    });
            }
        },
        [viewingChallenge?.type]
    );

    useEffect(() => {
        var currentChallenge = getCurrentChallenge(challenges);
        setViewingChallenge(currentChallenge);
        if (currentChallenge?.leaderboard_link) {
            fetchLeaderboard(currentChallenge.leaderboard_link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challenges]);

    useEffect(() => {
        if (viewingChallenge?.leaderboard_link) fetchLeaderboard(viewingChallenge.leaderboard_link);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewingChallenge]);

    useEffect(() => {
        fetchChallenges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            {viewingChallenge && (
                <>
                    <h1 className="pt-3">Leaderboard for {viewingChallenge.name}</h1>
                    <p>
                        Start Date: {getFriendlyDate(viewingChallenge.start_date)}, End Date: {getFriendlyDate(viewingChallenge.end_date)}
                    </p>
                </>
            )}
            {!viewingChallenge && <h1 className="pt-3">Leaderboard</h1>}
            <div className="row" id="dropdown-content">
                {challenges && (
                    <div>
                        <div className="d-flex justify-content-end">
                            <div className="d-flex align-items-center">View:</div>
                            <div className="dropdown ms-2">
                                <button
                                    className="btn btn-outline-secondary dropdown-toggle"
                                    type="button"
                                    id="selectChallengeDropdownButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {viewingChallenge ? viewingChallenge.name : "View..."}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="selectChallengeDropdownButton">
                                    {challenges.map((challenge: ChallengeModel, index: number) => {
                                        return (
                                            <li key={`challengeView_${index}`}>
                                                <button
                                                    type="button"
                                                    className={"dropdown-item" + (viewingChallenge?.id === challenge.id ? " active" : "")}
                                                    onClick={() => {
                                                        setViewingChallenge(challenge);
                                                    }}
                                                >
                                                    {challenge.name}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {loading && (
                <div className="row m-2" id="loading-spinner">
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
            {challenges.length === 0 && !loading && (
                <div className="row m-2" id="refresh-button">
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary btn-lg" onClick={() => fetchChallenges()}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-bootstrap-reboot"
                                viewBox="0 0 16 16"
                                style={{ marginRight: 10 }}
                            >
                                <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                            </svg>
                            Refresh
                        </button>
                    </div>
                </div>
            )}
            <div className="row" id="main-content">
                {leaderboard && viewingChallenge?.type !== ChallengeType.TeamsDraft1 && viewingChallenge?.type !== ChallengeType.TeamsDraft2 && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="col-md-1">#</th>
                                <th className="col-md-5">Name</th>
                                <th className="col-md-3">{getChallengeTypeTitle(viewingChallenge, getSortOrderForChallengeType(viewingChallenge?.type))}</th>
                                {viewingChallenge?.show_medals && <th className="col-md-3">Medals</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboard.map((user: LeaderboardModel, index: number) => {
                                return (
                                    <tr className={"" + (user.is_qualified ? "" : " table-danger")} key={index}>
                                        <td className="col-md-1">
                                            <a className="link-primary" href={"https://www.strava.com/athletes/" + user.id}>
                                                {index + 1}
                                            </a>
                                        </td>
                                        <td className="col-md-5">{`${user.first_name} ${user.last_name}`}</td>
                                        <td className="col-md-3">
                                            {getUserTotalForChallengeType(user, viewingChallenge, getSortOrderForChallengeType(viewingChallenge?.type))}
                                        </td>
                                        {viewingChallenge?.show_medals && (
                                            <td className="col-md-3">
                                                {viewingChallenge.type === ChallengeType.ThirtyXThirty && user.challenge_completed && (
                                                    <ThirtyXThirtyMedal width="30" height="30" title="30xThirty Medal" />
                                                )}
                                                {viewingChallenge.type === ChallengeType.Darvelo100 && user.challenge_completed && (
                                                    <ThirtyXThirtyMedal width="30" height="30" title="Darvelo100 Medal" />
                                                )}
                                                {viewingChallenge.gold_medal > 0 &&
                                                    getUserMedalComparisonValue(user, viewingChallenge) >= viewingChallenge.gold_medal && (
                                                        <GoldMedal width="30" height="30" title="Gold Challenge Medal" />
                                                    )}
                                                {viewingChallenge.silver_medal > 0 &&
                                                    getUserMedalComparisonValue(user, viewingChallenge) >= viewingChallenge.silver_medal && (
                                                        <SilverMedal width="30" height="30" title="Silver Challenge Medal" />
                                                    )}
                                                {viewingChallenge.bronze_medal > 0 &&
                                                    getUserMedalComparisonValue(user, viewingChallenge) >= viewingChallenge.bronze_medal && (
                                                        <BronzeMedal width="30" height="30" title="Bronze Challenge Medal" />
                                                    )}
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {leaderboard && (viewingChallenge?.type === ChallengeType.TeamsDraft1 || viewingChallenge?.type === ChallengeType.TeamsDraft2) && (
                    <>
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th className="col-1">#</th>
                                    <th className="col-5 col-sm-6">Name</th>
                                    <th className="col-3 text-end">Elevation</th>
                                    <th className="col-3 col-sm-2 text-end">Points</th>
                                </tr>
                            </thead>
                        </table>
                        {teamsLeaderboard.map((team: TeamsLeaderboardModel, index1: number) => {
                            return (
                                <table className={"table mb-0"} key={index1}>
                                    <tbody>
                                        <tr
                                            className={
                                                "table-" + (team.team_name ? BootstrapTeamsTableColours[index1 % BootstrapTeamsTableColours.length] : "danger")
                                            }
                                            onClick={() => {
                                                var hiddenTeamIndex = expandedTeams.findIndex((t) => t === `team_${index1}`);
                                                var newExpandedTeams = [...expandedTeams];
                                                if (hiddenTeamIndex === -1) {
                                                    newExpandedTeams.push(`team_${index1}`);
                                                } else {
                                                    newExpandedTeams.splice(hiddenTeamIndex, 1);
                                                }
                                                setExpandedTeams(newExpandedTeams);
                                            }}
                                        >
                                            <td className="col-1">{team.team_name ? index1 + 1 : ""}</td>
                                            <td className="col-5 col-sm-6">{team.team_name ? team.team_name : "Unallocated"}</td>
                                            <td className="col-3 text-end">
                                                {team.team_name ? Math.round(convertMetresToFeet(calculateTeamElevation(team)) * 10) / 10 : ""}
                                            </td>
                                            <td className="col-3 col-sm-2 text-end">{team.team_name ? calculateTeamPoints(team) : ""}</td>
                                        </tr>
                                        {team.team_members.map((user: LeaderboardModel, index2: number) => {
                                            return (
                                                <tr key={"teamUser_" + index1 + index2} hidden={!expandedTeams.includes("team_" + index1)}>
                                                    <td className="col-1">{team.team_name ? "" : index2 + 1}</td>
                                                    <td className={team.team_name ? "col-5 col-sm-6" : "col-11"} colSpan={team.team_name ? 1 : 3}>
                                                        <a
                                                            href={"https://www.strava.com/athletes/" + user.id}
                                                            style={{ textDecoration: "inherit", color: "inherit" }}
                                                        >{`${user.first_name} ${user.last_name}`}</a>
                                                    </td>
                                                    {team.team_name ? (
                                                        <>
                                                            <td className="col-3 text-end">
                                                                {team.team_name ? Math.round(convertMetresToFeet(user.total_elevation) * 10) / 10 : ""}
                                                            </td>
                                                            <td className="col-3 col-sm-2 text-end">{team.team_name ? user.total_points : ""}</td>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        })}
                    </>
                )}
            </div>
            <p className="text-muted">
                <br />
                *Activities may take up to 10 minutes to sync from Strava to the Leaderboards.
            </p>
        </div>
    );
}

export default Leaderboard;
