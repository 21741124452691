import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import ChallengeModel, { ChallengeType } from "./models/ChallengeModel";
import LeaderboardModel from "./models/LeaderboardModel";
import TeamsLeaderboardModel from "./models/TeamsLeaderboardModel";

export const ReloadNotificationId = "showReloadNotification";
export const AzureFunctionUri = "https://bhccc.azurewebsites.net/";
export const ConnectWithStravaLink =
    "http://www.strava.com/oauth/authorize?client_id=88390&response_type=code&redirect_uri=https://bhccc.azurewebsites.net/api/Authentication/Code&approval_prompt=force&scope=read,activity:read";
export const ChallengesJsonUri = "https://bhcccstorage.blob.core.windows.net/challenges-files/challenges.json";

export enum SortOrder {
    ThirtyXThirty = "ThirtyXThirty",
    Distance = "Distance",
    FirstName = "First Name",
    LastName = "Last Name",
    Time = "Time",
    Elevation = "Elevation",
    LongestDistance = "LongestDistance",
}

export const BootstrapCardColors = ["primary", "success", "danger", "warning"];
export const BootstrapTeamsTableColours = ["primary", "success", "warning", "info", "secondary"];

export async function skipWaitingAndReload() {
    var registeredSW = await navigator.serviceWorker.getRegistration();
    if (registeredSW) {
        if (registeredSW.waiting) {
            registeredSW.waiting.postMessage({ type: "SKIP_WAITING" });
        } else {
            window.location.reload();
        }
    }
}

export function UpdateReloadNotificationStorageValue(value: boolean) {
    localStorage.setItem(ReloadNotificationId, value ? "true" : "false");
    var showReloadNotificationEvent = new Event(ReloadNotificationId);
    window.dispatchEvent(showReloadNotificationEvent);
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export async function checkIfHasScopes(getAccessTokenSilently: Function, scopes: string[]) {
    var token = await getAccessTokenSilently();
    var decodedToken: any = jwt_decode(token);
    var foundAll = true;

    scopes.forEach((scope) => {
        if (!decodedToken.scope.includes(scope)) {
            foundAll = false;
        }
    });

    return foundAll;
}

export function getCurrentChallenge(challenges: ChallengeModel[]) {
    sortChallengesByStartDate(challenges);
    for (let i = 0; i < challenges.length; i++) {
        // if Date.now is between challenge start and end
        var challengeEndDate = parseDate(challenges[i].end_date);
        challengeEndDate.setHours(23);
        challengeEndDate.setMinutes(59);
        challengeEndDate.setSeconds(59);
        if (parseDate(challenges[i].start_date).valueOf() < Date.now().valueOf() && challengeEndDate.valueOf() > Date.now().valueOf()) {
            return challenges[i];
        }
    }

    for (let i = 0; i < challenges.length; i++) {
        // get first challenge that starts after Date.now
        if (parseDate(challenges[i].start_date).valueOf() > Date.now().valueOf()) {
            return challenges[i];
        }
    }

    sortChallengesByEndDate(challenges);

    if (challenges.length > 0) {
        return challenges[0];
    } else {
        return undefined;
    }
}

export function getChallengeTypeTitle(challenge: ChallengeModel | undefined, sortBy: SortOrder) {
    var type = challenge?.type;
    switch (type) {
        case ChallengeType.Time:
            return (
                <>
                    <div className="d-inline-flex">Time</div> <div className="d-inline-flex">(hh:mm:ss)</div>
                </>
            );
        case ChallengeType.Elevation:
            return (
                <>
                    <div className="d-inline-flex">Elevation</div> <div className="d-inline-flex">(m)</div>
                </>
            );
        case ChallengeType.Darvelo100:
            if (sortBy === SortOrder.Time)
                return (
                    <>
                        <div className="d-inline-flex">Time</div> <div className="d-inline-flex">(hh:mm:ss)</div>
                    </>
                );
            return (
                <>
                    <div className="d-inline-flex">Distance</div> <div className="d-inline-flex">(km)</div>
                </>
            );
        case ChallengeType.TeamsDraft1:
        case ChallengeType.TeamsDraft2:
            return <></>;
        case ChallengeType.Distance:
        case ChallengeType.ThirtyXThirty:
        case ChallengeType.LongestDistance:
        default:
            return (
                <>
                    <div className="d-inline-flex">Distance</div> <div className="d-inline-flex">(km)</div>
                </>
            );
    }
}

export function getSortOrderForChallengeType(challengeType: ChallengeType | undefined) {
    switch (challengeType) {
        case ChallengeType.Distance:
        case ChallengeType.Darvelo100:
            return SortOrder.Distance;
        case ChallengeType.ThirtyXThirty:
            return SortOrder.ThirtyXThirty;
        case ChallengeType.Elevation:
            return SortOrder.Elevation;
        case ChallengeType.Time:
            return SortOrder.Time;
        case ChallengeType.LongestDistance:
            return SortOrder.LongestDistance;
        case ChallengeType.TeamsDraft1:
        case ChallengeType.TeamsDraft2:
        default:
            return SortOrder.Distance;
    }
}

export function getUserTotalForChallengeType(user: LeaderboardModel, challenge: ChallengeModel | undefined, sortBy: SortOrder) {
    var type = challenge?.type;
    switch (type) {
        case ChallengeType.Time:
            return secondsToHms(user.total_time ? user.total_time : 0);
        case ChallengeType.Elevation:
            return convertTo2Decimals(user.total_elevation ? user.total_elevation : 0);
        case ChallengeType.LongestDistance:
            return convertTo2Decimals(user.longest_distance ? user.longest_distance : 0);
        case ChallengeType.Darvelo100:
            if (sortBy === SortOrder.Time) return secondsToHms(user.total_time ? user.total_time : 0);
            return convertTo2Decimals(user.total_distance ? user.total_distance : 0);
        case ChallengeType.Distance:
        case ChallengeType.ThirtyXThirty:
        case ChallengeType.TeamsDraft1:
        case ChallengeType.TeamsDraft2:
        default:
            return convertTo2Decimals(user.total_distance ? user.total_distance : 0);
    }
}

export function getUserMedalComparisonValue(user: LeaderboardModel, challenge: ChallengeModel | undefined): number {
    if (challenge) {
        switch (challenge.type) {
            case ChallengeType.Time:
                return user.total_time;
            case ChallengeType.Elevation:
                return user.total_elevation;
            case ChallengeType.LongestDistance:
                return user.longest_distance;
            case ChallengeType.Darvelo100:
            case ChallengeType.Distance:
            case ChallengeType.ThirtyXThirty:
            case ChallengeType.TeamsDraft1:
            case ChallengeType.TeamsDraft2:
            default:
                return user.total_distance;
        }
    }
    return 0;
}

export function convertTo2Decimals(d: number) {
    return Math.round(d * 100) / 100;
}

export function secondsToHms(d: number) {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
}

export function getFriendlyDate(dateStr: string) {
    var splitDate = dateStr.split("-");
    var year = parseInt(splitDate[0], 10);
    var month = parseInt(splitDate[1], 10);
    var day = parseInt(splitDate[2], 10);
    return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
}

export function convertKmToMiles(kmNumber: number) {
    return kmNumber / 1.60934;
}

export function convertMetresToFeet(metres: number) {
    return metres * 3.28084;
}

/** Sorting */

export function sortLeaderboard(leaderboard: LeaderboardModel[], sortBy: SortOrder) {
    if (leaderboard) {
        switch (sortBy) {
            case SortOrder.ThirtyXThirty:
                sortLeaderboardByQualifiers(leaderboard);
                break;
            case SortOrder.Distance:
                sortLeaderboardByDistance(leaderboard);
                break;
            case SortOrder.FirstName:
                sortLeaderboardByFirstName(leaderboard);
                break;
            case SortOrder.LastName:
                sortLeaderboardByLastName(leaderboard);
                break;
            case SortOrder.Elevation:
                sortLeaderboardByElevation(leaderboard);
                break;
            case SortOrder.Time:
                sortLeaderboardByTime(leaderboard);
                break;
            case SortOrder.LongestDistance:
                sortLeaderboardByLongestDistance(leaderboard);
                break;
        }
    }
    return leaderboard;
}

export function sortTeamsLeaderboard(teamsLeaderboard: TeamsLeaderboardModel[], challengeType: ChallengeType = ChallengeType.TeamsDraft1) {
    if (teamsLeaderboard) {
        switch (challengeType) {
            case ChallengeType.TeamsDraft1:
            case ChallengeType.TeamsDraft2:
            default:
                return sortTeamsLeaderboardForTeamsDraft1(teamsLeaderboard);
        }
    }
    return teamsLeaderboard;
}

export function sortTeamsLeaderboardForTeamsDraft1(teamsLeaderboard: TeamsLeaderboardModel[]) {
    teamsLeaderboard.forEach((team: TeamsLeaderboardModel) => {
        sortLeaderboardByPoints(team.team_members);
    });
    return teamsLeaderboard.sort((team1: TeamsLeaderboardModel, team2: TeamsLeaderboardModel) => {
        if (!team1.team_name) {
            return 1;
        }
        if (!team2.team_name) {
            return -1;
        }

        var team1Points = calculateTeamPoints(team1);
        var team1Elev = calculateTeamElevation(team1);
        var team1Dist = calculateTeamDistance(team1);

        var team2Points = calculateTeamPoints(team2);
        var team2Elev = calculateTeamElevation(team2);
        var team2Dist = calculateTeamDistance(team2);

        var pointDiff = team2Points - team1Points;
        if (pointDiff) return pointDiff;

        var elevDiff = team2Elev - team1Elev;
        if (elevDiff) return elevDiff;

        var teamDiff = team2Dist - team1Dist;
        if (teamDiff) return teamDiff;

        return team1.team_name.localeCompare(team2.team_name, undefined, {
            numeric: true,
            sensitivity: "base",
        });

        // var teamName1 = team1.team_name.toUpperCase();
        // var teamName2 = team2.team_name.toUpperCase();
        // if (teamName1 < teamName2) {
        //     return -1;
        // }
        // if (teamName1 > teamName2) {
        //     return 1;
        // }
        // return 0;
    });
}

export function calculateTeamPoints(team: TeamsLeaderboardModel) {
    var points = 0;
    for (let i = 0; i < team.team_members.length; i++) {
        points += team.team_members[i].total_points;
    }
    return points;
}

export function calculateTeamElevation(team: TeamsLeaderboardModel) {
    var elevation = 0;
    for (let i = 0; i < team.team_members.length; i++) {
        elevation += team.team_members[i].total_elevation;
    }
    return elevation;
}

export function calculateTeamDistance(team: TeamsLeaderboardModel) {
    var distance = 0;
    for (let i = 0; i < team.team_members.length; i++) {
        distance += team.team_members[i].total_distance;
    }
    return distance;
}

export function sortChallengesByStartDate(challenges: ChallengeModel[]) {
    challenges.sort((a: ChallengeModel, b: ChallengeModel) => {
        // Turn strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return parseDate(a.start_date).valueOf() - parseDate(b.start_date).valueOf();
    });
}

export function sortChallengesByEndDate(challenges: ChallengeModel[]) {
    challenges.sort((a: ChallengeModel, b: ChallengeModel) => {
        // Turn strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return parseDate(b.end_date).valueOf() - parseDate(a.end_date).valueOf();
    });
}

function parseDate(dateStr: string): Date {
    var splitDate = dateStr.split("-");
    var year = parseInt(splitDate[0], 10);
    var month = parseInt(splitDate[1], 10);
    var day = parseInt(splitDate[2], 10);
    return new Date(year, month - 1, day);
}

export function sortLeaderboardByQualifiers(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        if (a.is_qualified && b.is_qualified) {
            // if both users are qualified
            if (b.total_distance === a.total_distance) {
                return compareFirstThenLastName(a, b);
            }
            return b.total_distance - a.total_distance; // sort by distance
        } else if (a.is_qualified) {
            // if only a is qualified
            return -1;
        } else if (b.is_qualified) {
            // if only b is qualified
            return 1;
        }
        // if none are qualified
        var distance = b.total_distance - a.total_distance;
        if (distance) return distance; // sort by distance

        return compareFirstThenLastName(a, b); // sort by name
    });
}

export function sortLeaderboardByDistance(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var distance = b.total_distance - a.total_distance;
        if (distance) return distance;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByLongestDistance(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var distance = b.longest_distance - a.longest_distance;
        if (distance) return distance;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByElevation(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var elevation = b.total_elevation - a.total_elevation;
        if (elevation) return elevation;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByPoints(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var pointDiff = b.total_points - a.total_points;
        if (pointDiff) return pointDiff;

        var elevation = b.total_elevation - a.total_elevation;
        if (elevation) return elevation;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByTime(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var time = b.total_time - a.total_time;
        if (time) return time;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByFirstName(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByLastName(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var nameA = a.last_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.last_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        var firstNameA = a.first_name.toUpperCase();
        var firstNameB = b.first_name.toUpperCase();
        if (firstNameA < firstNameB) {
            return -1;
        }
        if (firstNameA > firstNameB) {
            return 1;
        }
        return 0;
    });
}

function compareFirstThenLastName(a: LeaderboardModel, b: LeaderboardModel) {
    var nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    var lastNameA = a.last_name.toUpperCase();
    var lastNameB = b.last_name.toUpperCase();
    if (lastNameA < lastNameB) {
        return -1;
    }
    if (lastNameA > lastNameB) {
        return 1;
    }
    return 0;
}

/** Sorting */
