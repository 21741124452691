import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = ({ ...props }) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <button {...props} onClick={() => loginWithRedirect()}>
            Admin Log In
        </button>
    );
};

const SignupButton = ({ ...props }) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <button
            {...props}
            onClick={() =>
                loginWithRedirect({
                    screen_hint: "signup",
                })
            }
        >
            Sign Up
        </button>
    );
};

const LogoutButton = ({ ...props }) => {
    const { logout } = useAuth0();
    return (
        <button
            {...props}
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            Log Out
        </button>
    );
};

export default LoginButton;
export { SignupButton, LogoutButton };
