import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AzureFunctionUri, useQuery } from "./Constants";
import ChallengeModel, { ChallengeType } from "./models/ChallengeModel";

function CreateChallenge() {
    const [loading, setLoading] = useState(true);
    const [challenge, setChallenge] = useState({
        type: ChallengeType.Distance,
    } as ChallengeModel);

    const [challengeId, setChallengeId] = useState("");

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const query = useQuery();
    const history = useHistory();

    function handleInputChange(event: any) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        var newChallenge: any = { ...challenge };
        if (newChallenge) {
            newChallenge[name] = value;
            setChallenge(newChallenge);
        }
    }

    const getChallenge = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/Challenges/" + challengeId, {
            method: "GET",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. ChallengeById (GET: Challenges): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: ChallengeModel) => {
                if (isMountedRef.current) {
                    setChallenge(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) setLoading(false);
                history.push("/Challenges?errorMessage=Unable+to+get+challenge.");
            });
    }, [challengeId, getAccessTokenSilently, history, isAuthenticated, isLoading]);

    function submitChallenge(event: any) {
        event.preventDefault();

        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setLoading(true);

        getAccessTokenSilently().then((token) => {
            fetch(AzureFunctionUri + "api/Challenges", {
                method: "POST",
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(challenge),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok. Challenges (POST: Challenges): ${response.statusText}(${response.status})`);
                    }
                    isMountedRef.current = false;
                    history.push("/Challenges?successMessage=Successfully+created+challenge.");
                })
                .catch((error) => {
                    console.error(error);
                    isMountedRef.current = false;
                    setLoading(false);
                    history.push("?errorMessage=Failed+to+create+or+edit+challenge.");
                });
        });
    }

    useEffect(() => {
        if (!challengeId) {
            if (query.has("id")) {
                setChallengeId(query.get("id") ?? "");
            } else {
                setLoading(false);
            }
        }
    }, [history, query, challengeId]);

    useEffect(() => {
        if (challengeId) {
            getChallenge();
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [challengeId, getChallenge]);

    return (
        <div className="container">
            <h1 className="pt-3">Create/Edit Challenge</h1>
            {!isLoading && isAuthenticated && (
                <>
                    <div className="row m-2" id="loading-spinner">
                        {loading && (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <form onSubmit={submitChallenge}>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeNameInput" className="col-4 col-sm-3 col-lg-2">
                                Name:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="challengeNameInput"
                                    placeholder="Challenge Name"
                                    value={challenge.name ?? ""}
                                    name="name"
                                    onChange={handleInputChange}
                                    required
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeStartDateInput" className="form-label col-4 col-sm-3 col-lg-2">
                                Start Date:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4 my-1">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="challengeStartDateInput"
                                    placeholder="Start Date"
                                    name="start_date"
                                    value={challenge.start_date ?? ""}
                                    onChange={handleInputChange}
                                    required
                                    max={challenge.end_date ?? ""}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeEndDateInput" className="form-label col-4 col-sm-3 col-lg-2">
                                End Date:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4 my-1">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="challengeEndDateInput"
                                    placeholder="End Date"
                                    name="end_date"
                                    value={challenge.end_date ?? ""}
                                    onChange={handleInputChange}
                                    required
                                    min={challenge.start_date ?? ""}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <label className="col-4 col-sm-3 col-lg-2" htmlFor="challengeTypeSelect">
                                Type:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4">
                                <select
                                    className="form-select"
                                    aria-label="Select Challenge Type"
                                    id="challengeTypeSelect"
                                    name="type"
                                    value={challenge.type ?? ChallengeType.Distance}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                >
                                    <option value={ChallengeType.Distance}>{ChallengeType.Distance}</option>
                                    <option value={ChallengeType.ThirtyXThirty}>{ChallengeType.ThirtyXThirty}</option>
                                    <option value={ChallengeType.LongestDistance}>{ChallengeType.LongestDistance}</option>
                                    <option value={ChallengeType.Elevation}>{ChallengeType.Elevation}</option>
                                    <option value={ChallengeType.Time}>{ChallengeType.Time}</option>
                                    <option value={ChallengeType.Darvelo100}>{ChallengeType.Darvelo100}</option>
                                    <option value={ChallengeType.TeamsDraft1}>{ChallengeType.TeamsDraft1}</option>
                                    <option value={ChallengeType.TeamsDraft2}>{ChallengeType.TeamsDraft2}</option>
                                </select>
                            </div>
                        </div>
                        <div className="row my-2 align-items-center">
                            <div className="col-4 col-sm-3 col-lg-2">Is Ongoing?</div>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={challenge.is_ongoing ?? false}
                                    id="challengeOngoingCheck"
                                    name="is_ongoing"
                                    onChange={handleInputChange}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="row my-2 align-items-center">
                            <div className="col-4 col-sm-3 col-lg-2">Show Medals?</div>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={challenge.show_medals ?? false}
                                    id="challengeShowMedals"
                                    name="show_medals"
                                    onChange={handleInputChange}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeGoldMedal" className="col-4 col-sm-3 col-lg-2">
                                Gold Medal:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    id="challengeGoldMedal"
                                    name="gold_medal"
                                    aria-describedby="challengeGoldMedal"
                                    value={challenge.gold_medal ?? 0}
                                    step={1}
                                    onChange={handleInputChange}
                                    disabled={!challenge.show_medals || loading}
                                />
                            </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeSilverMedal" className="col-4 col-sm-3 col-lg-2">
                                Silver Medal:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    id="challengeSilverMedal"
                                    name="silver_medal"
                                    aria-describedby="challengeSilverMedal"
                                    value={challenge.silver_medal ?? 0}
                                    step={1}
                                    onChange={handleInputChange}
                                    disabled={!challenge.show_medals || loading}
                                />
                            </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                            <label htmlFor="challengeBronzeMedal" className="col-4 col-sm-3 col-lg-2">
                                Bronze Medal:
                            </label>
                            <div className="col-8 col-md-6 col-lg-4">
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    id="challengeBronzeMedal"
                                    name="bronze_medal"
                                    aria-describedby="challengeBronzeMedal"
                                    value={challenge.bronze_medal ?? 0}
                                    step={1}
                                    onChange={handleInputChange}
                                    disabled={!challenge.show_medals || loading}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="challengeDescription" className="form-label">
                                Challenge Description
                            </label>
                            <textarea
                                className="form-control"
                                id="challengeDescription"
                                name="description"
                                aria-describedby="challengeDescription"
                                value={challenge.description ?? ""}
                                onChange={handleInputChange}
                                rows={3}
                                disabled={loading}
                            ></textarea>
                        </div>
                        <button type="submit" className="btn btn-success my-2" disabled={loading}>
                            Create/Edit
                        </button>
                    </form>
                </>
            )}
            <div className="d-flex flex-row-reverse">
                <Link to="/Challenges" className="btn btn-primary">
                    Go Back
                </Link>
            </div>
        </div>
    );
}

export default CreateChallenge;
