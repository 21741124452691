import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfHasScopes } from "./Constants";
import ChallengeModel from "./models/ChallengeModel";
import UserModel from "./models/UserModel";

function Users() {
    const [loading, setLoading] = useState(true);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [users, setUsers] = useState([] as UserModel[]);
    const [viewingUser, setViewingUser] = useState(undefined as UserModel | undefined);
    const [challenges, setChallenges] = useState([] as ChallengeModel[]);
    const [selectedChallenge, setSelectedChallenge] = useState(undefined as ChallengeModel | undefined);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const history = useHistory();

    const fetchUsers = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["read:users"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Users", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Users (GET: Users): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data) => {
                if (isMountedRef.current) {
                    setUsers(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: " + error);
                if (isMountedRef.current) {
                    setUsers([] as UserModel[]);
                    setLoading(false);
                }
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);

    const fetchChallenges = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["read:challenges"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Challenges", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Challenges (GET: Challenges): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: ChallengeModel[]) => {
                if (isMountedRef.current) {
                    setChallenges(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);

    async function backtrackUser() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["update:users"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(`${AzureFunctionUri}api/Users/${viewingUser?.id}/Backtrack/${selectedChallenge?.id}`, {
            method: "PUT",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. BacktrackUser (PUT: Backtrack): ${response.statusText}(${response.status})`);
                }

                if (isMountedRef.current) {
                    setViewingUser(undefined);
                    setSelectedChallenge(undefined);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log("Error: " + error);
                if (isMountedRef.current) {
                    setViewingUser(undefined);
                    setSelectedChallenge(undefined);
                    setLoading(false);
                }
            });
    }

    async function deleteUser() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["delete:users"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Users/" + viewingUser?.id, {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Users (DELETE: Users): ${response.statusText}(${response.status})`);
                }
                if (isMountedRef.current) {
                    fetchUsers();
                    setViewingUser(undefined);
                    setLoading(false);
                    history.push("?successMessage=Successfully+deleted+user.");
                }
            })
            .catch((error) => {
                console.error("Error: " + error);
                if (isMountedRef.current) {
                    setLoading(false);
                    setViewingUser(undefined);
                    history.push("?errorMessage=Failed+to+delete+user.");
                }
            });
    }

    function handleChallengeSelect(event: any) {
        var challenge = challenges?.find((c) => c.id === event.target.value);
        if (challenge) {
            setSelectedChallenge(challenge);
        } else {
            setSelectedChallenge(undefined);
        }
    }

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchChallenges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <h1>Users</h1>
            {!isLoading && isAuthenticated && (
                <>
                    <div className="row m-2" id="loading-spinner">
                        {loading && (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row m-2" id="refresh-button">
                        {!loading && (
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary btn-lg" onClick={() => fetchUsers()}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-bootstrap-reboot"
                                        viewBox="0 0 16 16"
                                        style={{ marginRight: 10 }}
                                    >
                                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                                    </svg>
                                    Refresh
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="row" id="main-content">
                        {users && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user: UserModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a className="link-primary" href={"https://www.strava.com/athletes/" + user.id}>
                                                        {index + 1}
                                                    </a>
                                                </td>
                                                <td className="align-middle">{`${user.first_name} ${user.last_name}`}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-secondary float-end"
                                                        onClick={() => {
                                                            setViewingUser(user);
                                                            setShowUserModal(true);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-gear-fill align-middle"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"></path>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </>
            )}
            <Modal
                centered={true}
                id="userInfoModal"
                show={showUserModal}
                onHide={() => setShowUserModal(false)}
                aria-labelledby="userInfoModalLabel"
                aria-hidden="true"
                tabIndex={-1}
                size="sm"
            >
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="userInfoModalLabel">User Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Strava: {viewingUser?.id && <a href={"https://www.strava.com/athletes/" + viewingUser?.id}>View Profile</a>}
                    <br />
                    Name: {`${viewingUser?.first_name} ${viewingUser?.last_name}`}
                    <br />
                    <br />
                    <h5>Backtrack Activities</h5>
                    {challenges && challenges.length > 0 && (
                        <>
                            <select
                                className="form-select my-1"
                                aria-label="Select Challenge"
                                id="challengeSelect"
                                name="challenge"
                                value={selectedChallenge ? selectedChallenge.id : "select"}
                                onChange={handleChallengeSelect}
                            >
                                <option value="select">Select...</option>
                                {challenges.map((c: ChallengeModel, index: number) => {
                                    return (
                                        <option key={index} value={c.id} disabled={!c.is_ongoing}>
                                            {c.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="btn btn-primary"
                                disabled={selectedChallenge && viewingUser?.id ? false : true}
                                onClick={() => {
                                    backtrackUser().then(() => setShowUserModal(false));
                                }}
                            >
                                Backtrack
                            </button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger" onClick={() => setShowDeleteUserModal(true)}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered={true}
                id="deleteUserModal"
                show={showDeleteUserModal}
                onHide={() => setShowDeleteUserModal(false)}
                tabIndex={-1}
                aria-labelledby="deleteUserModalLabel"
                aria-hidden="true"
            >
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="deleteUserModalLabel">Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        Are you sure you want to delete user '{`${viewingUser?.first_name} ${viewingUser?.last_name}`}' with id '{viewingUser?.id}'?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteUserModal(false)}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                            deleteUser().then(() => {
                                setShowDeleteUserModal(false);
                                setShowUserModal(false);
                            });
                        }}
                        disabled={viewingUser?.id ? false : true}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Users;
