import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfHasScopes, sortChallengesByStartDate } from "./Constants";
import ChallengeModel from "./models/ChallengeModel";

function Challenges() {
    const [loading, setLoading] = useState(true);
    const [showChallengeModal, setShowChallengeModal] = useState(false);
    const [showDeleteChallengeModal, setShowDeleteChallengeModal] = useState(false);
    const [challenges, setChallenges] = useState([] as ChallengeModel[]);
    const [viewingChallenge, setViewingChallenge] = useState({} as ChallengeModel);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const history = useHistory();

    const fetchChallenges = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["read:challenges"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Challenges", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Challenges (GET: Challenges): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: ChallengeModel[]) => {
                if (isMountedRef.current) {
                    sortChallengesByStartDate(data);
                    setChallenges(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);

    async function deleteChallenge() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfHasScopes(getAccessTokenSilently, ["delete:challenges"]))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Challenges/" + viewingChallenge.id, {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Challenges (DELETE: Challenges): ${response.statusText}(${response.status})`);
                }
                fetchChallenges();
                history.push("/Challenges?successMessage=Successfully+deleted+challenge.");
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    useEffect(() => {
        fetchChallenges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="pt-2">
                <h1 className="d-inline">Challenges</h1>
                {!isLoading && isAuthenticated && (
                    <div className="d-inline float-end mx-2">
                        <Link to="/Challenges/Create" className="btn btn-success">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-plus-circle align-middle"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </Link>
                    </div>
                )}
            </div>
            {!isLoading && isAuthenticated && (
                <>
                    <div className="row m-2" id="loading-spinner">
                        {loading && (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row m-2" id="refresh-button">
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary btn-lg" onClick={() => fetchChallenges()} disabled={loading}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    className="bi bi-bootstrap-reboot"
                                    viewBox="0 0 16 16"
                                    style={{ marginRight: 10 }}
                                >
                                    <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                    <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                                </svg>
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="row" id="main-content">
                        {challenges && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {challenges.map((challenge: ChallengeModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="align-middle">{challenge.name}</td>
                                                <td>
                                                    {challenge.id && (
                                                        <Link
                                                            to={"/Challenges/Create?id=" + challenge.id}
                                                            className="btn btn-primary float-end mx-1"
                                                            id={"challengeEdit_" + challenge.id}
                                                        >
                                                            Edit
                                                        </Link>
                                                    )}
                                                    <button
                                                        className="btn btn-secondary float-end mx-1"
                                                        onClick={() => {
                                                            setViewingChallenge(challenge);
                                                            setShowChallengeModal(true);
                                                        }}
                                                    >
                                                        Info
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </>
            )}
            <Modal
                centered={true}
                show={showChallengeModal}
                onHide={() => setShowChallengeModal(false)}
                aria-labelledby="challengeModal"
                aria-hidden="true"
                tabIndex={-1}
            >
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="challengesModalLabel">Challenge Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        <div>
                            <b>Id:</b> {viewingChallenge.id}
                        </div>
                        <div>
                            <b>Name:</b> {viewingChallenge.name}
                        </div>
                        <div>
                            <b>Start Date:</b> {viewingChallenge.start_date}
                        </div>
                        <div>
                            <b>End Date:</b> {viewingChallenge.end_date}
                        </div>
                        <div>
                            <b>Type:</b> {viewingChallenge.type}
                        </div>
                        <div>
                            <b>Is Ongoing:</b> {viewingChallenge.is_ongoing ? " true" : " false"}
                        </div>
                        <div>
                            <b>Show Medals:</b> {viewingChallenge.show_medals ? " true" : " false"}
                        </div>
                        <div>
                            <b>Gold Medal:</b> {viewingChallenge.gold_medal}
                        </div>
                        <div>
                            <b>Silver Medal:</b> {viewingChallenge.silver_medal}
                        </div>
                        <div>
                            <b>Bronze Medal:</b> {viewingChallenge.bronze_medal}
                        </div>
                        <div>
                            <b>Description:</b> {viewingChallenge.description} <br />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {viewingChallenge.id && (
                        <Link to={"/Challenges/Create?id=" + viewingChallenge.id} className="btn btn-primary" id={"challengeEdit_" + viewingChallenge.id}>
                            Edit
                        </Link>
                    )}
                    <button type="button" className="btn btn-danger" onClick={() => setShowDeleteChallengeModal(true)}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered={true}
                show={showDeleteChallengeModal}
                onHide={() => setShowDeleteChallengeModal(false)}
                tabIndex={-1}
                aria-labelledby="deleteChallengeModalLabel"
                aria-hidden="true"
            >
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="deleteChallengeModalLabel">Delete Challenge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        Are you sure you want to delete challenge '{viewingChallenge.name}' with id '{viewingChallenge.id}
                        '?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => setShowDeleteChallengeModal(false)}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                            deleteChallenge().then(() => {
                                setShowDeleteChallengeModal(false);
                                setShowChallengeModal(false);
                            });
                        }}
                        disabled={viewingChallenge.id ? false : true}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Challenges;
