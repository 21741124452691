import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BootstrapCardColors, ChallengesJsonUri, ConnectWithStravaLink, getFriendlyDate, sortChallengesByStartDate } from "./Constants";
import ConnectWithStravaImage from "./images/btn_strava_connectwith_orange.png";
import ChallengeModel from "./models/ChallengeModel";

function Home() {
    const [loading, setLoading] = useState(true);
    const [challenges, setChallenges] = useState([] as ChallengeModel[]);

    const fetchChallenges = useCallback(async () => {
        return fetch(ChallengesJsonUri, { cache: "no-cache" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Leaderboard (GET: ChallengesJson): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: ChallengeModel[]) => {
                var ongoingChallengs = data.filter((c) => {
                    return c.is_ongoing;
                });
                sortChallengesByStartDate(ongoingChallengs);
                setChallenges(ongoingChallengs);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error: ", error);
                setChallenges([] as ChallengeModel[]);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchChallenges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <main role="main" className="pb-3">
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col">
                        <h1 className="text-center">Home</h1>
                        <p>
                            Welcome to the Balsall Heath Community Cycling Club challenges. <br /> <br />
                            We aim to help you get on your bike as frequently as possible. <br /> <br />
                            By signing up for the challenge, you can track your weekly performances and have the opportunity to win some fantastic prizes.{" "}
                            <br /> <br />
                            The challenges will be designed to cater for cyclists of all abilities, whether you are a beginner or a seasoned cyclists, there's
                            something for all. <br /> <br />
                            Sign up today, connect our app to your strava and get cycling. <br />
                        </p>
                        <div className="text-center pb-3">
                            <a href={ConnectWithStravaLink}>
                                <img src={ConnectWithStravaImage} alt="Connect with Strava Link" />
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <h3 className="text-center">Ongoing Challenges</h3>
                        {loading && (
                            <div className="row m-2" id="loading-spinner">
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!loading && challenges.length === 0 && <p className="text-center fst-italic">No ongoing challenges.</p>}
                        {!loading &&
                            challenges.length > 0 &&
                            challenges.map((challenge, index1) => {
                                return (
                                    <Card border={BootstrapCardColors[index1 % BootstrapCardColors.length]} className="mb-3" key={`challengeCard_${index1}`}>
                                        <Card.Header className={"bg-" + BootstrapCardColors[index1 % BootstrapCardColors.length] + " text-white"}>
                                            {challenge.name}
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6>Start Date: {getFriendlyDate(challenge.start_date)}</h6>
                                                    <h6>End Date: {getFriendlyDate(challenge.end_date)}</h6>
                                                </div>
                                                <div className="col-4">
                                                    <Link
                                                        to={"/Leaderboard"}
                                                        className={
                                                            "btn btn-" + BootstrapCardColors[index1 % BootstrapCardColors.length] + " float-end text-white"
                                                        }
                                                    >
                                                        View
                                                    </Link>
                                                </div>
                                            </div>
                                            <Card.Text as="div">
                                                {challenge.description &&
                                                    challenge.description.split("\n").map((c: string, index2: number) => {
                                                        if (!c) {
                                                            return <br key={`challengeDesc_${index1}${index2}`} />;
                                                        }
                                                        return <div key={`challengeDesc_${index1}${index2}`}>{c}</div>;
                                                    })}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                );
                            })}
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Home;
